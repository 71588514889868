import { faArrowsAltH, faBed } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image";
import React, {useState} from "react";
import '../Card/Card.css'
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import CardSeller from "../Card/CardSeller/CardSeller";

const CardBinnenkort = (props) => {
    const styles = props.styles
    const data = props.data
    const sellerData = props.seller
   
   
    const length = props.length

    const koopConditie = data.binnenkortVerkoop.koopconditie
    
   
    let img;

    const key = props.delay
    const animDelay = key !== undefined ? key * 0.15 : 0
    const woonoppervlak = <span>{data.binnenkortVerkoop.woonoppervlakte}m²</span>

    let seller = null
    
    seller = sellerData.nodes.filter((sell) => {
        if(data.categories.nodes.includes(sell.verkopendeMakelaars.vestiging)){
            return true;
        } else{ return false }
    })
    const sellerName = seller.length > 0 ? seller[0].title : ''
    const sellerVestiging = seller.length > 0 ? `` : `Groenhout ${data.categories.nodes[0].name}`
    const phoneNumber = seller.length > 0 ? seller[0].verkopendeMakelaars.telefoonnummer : ''
    const image = seller.length > 0 ? seller[0].verkopendeMakelaars.foto.mediaItemUrl : ''
    const phoneDisplay = phoneNumber !== '' ?
        phoneNumber.startsWith("06") ?  
            phoneNumber.substr(0,2) + ' - ' + phoneNumber.substr(2) 
        :
            phoneNumber.substr(0,4) + ' - ' + phoneNumber.substr(4) 
        :
        '' 

    
    img = data.binnenkortVerkoop.hoofdfoto?.mediaItemUrl;
    
    const [cardState, setCardState] = useState(null)
    console.log(data)
    return(
        <>
            <div className="max-h-3/6">
            <Link to={data.uri}>
                <motion.div layoutId={key} initial={{translateY: 75, opacity: 0}} animate={{translateY: 0, opacity: 1}} transition={{duration: 0.2, delay: animDelay }} onClick={() => setCardState(key)} className={` card ${length > 3 ? ``: ``} p-0 transition-all duration-300 ease-in shadow hover:shadow-none ${styles} col-span-1 row-span-1 relative `} >
                    <span className={`bg-red opacity-100 text-white text-sm absolute pt-2 pb-1 px-5 top-0 left-0 z-20 `}>Binnenkort</span>
                        <div className="object-cover w-full h-3/6 m-0 image_container ">
                            <div className="flex row ">
                                <div className="max-h-full w-full">
                                    {data.binnenkortVerkoop.hoofdfoto !== null ?
                                    <img src={img} className={`h-48 max-h-2/6 object-cover w-full mb-0 z-10`}></img>
                                    : 
                                    null
                                    }
                                </div>
                            </div>
                            <div className="flex flex-row h-full p-5 bg-light justify-between">
                                <div className="flex flex-col h-full">
                                    <h3 className="text-blue text-lg">{data.title}</h3>
                                    <div className="">
                                        <p className="text-blue text-sm m-0">Binnenkort in de verkoop bij {`${sellerName} ${sellerVestiging}`}</p>
                                        <a href={`https://api.whatsapp.com/send/?phone=31${phoneNumber.replace(/\s/g, '').replace('-', '')}`} ><span className="text-red text-sm mt-1">{phoneDisplay}</span></a>
                                        {image !== '' ?
                                        <img src={image} className="absolute rounded-full w-3/12 -bottom-4 right-8 m-0 object-cover" style={{width: 90, height: 90}}></img>
                                        : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                </motion.div>
            </Link>
            </div>
        </>
    )
}

export default CardBinnenkort;