
import { useStaticQuery, graphql } from "gatsby"
export const useBinnenkortQuery = () => {

  const data = useStaticQuery(
  graphql`
  query BinnenkortQuery {
    allWpVerkopendeMakelaar {
      nodes {
        title
        verkopendeMakelaars {
          fieldGroupName
          foto {
            mediaItemUrl
           
          }
          telefoonnummer
          vestiging
          volgnummer
        }
      }
    }
     allWpBinnenkortInDeVerkoop {
      nodes {
        categories {
          nodes {
            name
          }
        }
      uri
      title
      binnenkortVerkoop {
        bouwjaar
        energieklasse
        inhoud
        kamers
        objectType
        perceeloppervlakte
        slaapkamers
        typeWoning
        woonoppervlakte
        plaats
        hoofdfoto {
            mediaItemUrl
          }
      }
      
    }
  }
  }
  `
)

  return data;
}

