import React, {useContext} from "react";
import Card from "../Card/Card"
import "../CardGrid/CardGrid.css"
import { motion } from "framer-motion";
import VestigingContext from "../../store/vestiging-context";
import CardBinnenkort from "./CardBinnenkort";

const CardGridBinnenkort = (props) => {

    const data = props.data
    const seller = data.allWpVerkopendeMakelaar

    const selectedVestiging = useContext(VestigingContext);
    
    const filtered = data.allWpBinnenkortInDeVerkoop.nodes.filter((e) => { 
        if (selectedVestiging !== undefined && e.categories.nodes[0].name === selectedVestiging.vestiging){ 
            return true;
        }else if(selectedVestiging.vestiging !== undefined){
            return false;
        }else{ 
            return true;
        }
    })

    let min = props.minVal
    let max = props.maxVal
    let type = props.type.replace('_', ' ')
    let minOpp = props.oppMin
    let maxOpp = props.oppMax
    let plaats = props.plaats
    let slaapkamers = props.slaapkamers
    
    const filterSold = filtered.filter((e) => {
        if(
            type !== '' && type !== e.binnenkortVerkoop.typeWoning
            ||
            min !== '' && parseInt(e.binnenkortVerkoop.koopprijs) < min 
            || 
            max !== '' && parseInt(e.binnenkortVerkoop.koopprijs) > max 
            || 
            minOpp !== '' && e.binnenkortVerkoop.woonoppervlakte < minOpp
            || 
            maxOpp !== '' && e.binnenkortVerkoop.woonoppervlakte > maxOpp 
            || 
            minOpp !== '' && e.binnenkortVerkoop.woonoppervlakte === '' 
            || 
            maxOpp !== '' && e.binnenkortVerkoop.woonoppervlakte === ''
            || 
            plaats.length !== 0 && !plaats.includes(e.binnenkortVerkoop.plaats)  
            || 
            slaapkamers !== '' && e.binnenkortVerkoop.slaapkamers < slaapkamers
        ){
            return false
        } else{return true}
    })
    
    const woningen = filterSold.map((e, i) => (
        <CardBinnenkort length={filtered.length} key={i} delay={i} data={e} seller={seller} />
    ))

    props.count(woningen.length)

    return(
        <div className="aanbod__grid grid lg:grid-cols-3 grid-cols-1 gap-8 full:w-9/12 w-full max-h-auto h-auto lg:p-0 p-8 m-0">
            {woningen}
        </div>
    )
}

export default CardGridBinnenkort;